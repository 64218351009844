import { render, staticRenderFns } from "./VirtualAccountResult.vue?vue&type=template&id=021b511c&scoped=true"
import script from "./VirtualAccountResult.vue?vue&type=script&lang=js"
export * from "./VirtualAccountResult.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "021b511c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/AzDevOps/myagent/_work/11/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('021b511c')) {
      api.createRecord('021b511c', component.options)
    } else {
      api.reload('021b511c', component.options)
    }
    module.hot.accept("./VirtualAccountResult.vue?vue&type=template&id=021b511c&scoped=true", function () {
      api.rerender('021b511c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/settle/popup/VirtualAccountResult.vue"
export default component.exports